<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">Usuários</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item"><a href="#">Home</a></li>
                     <li class="breadcrumb-item active">Dashboard</li>
                     <li class="breadcrumb-item active">Usuários</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">
            <!-- /.row -->
            <div class="row">
               <div class="col-12">
                  <div class="card">
                     <div class="card-header">
                        <router-link
                           :to="{ name: 'usersCreate' }"
                           class="btn btn-success"
                        >
                           <i class="fas fa-plus-circle"></i> Cadastrar Usuário
                        </router-link>

                        <div class="card-tools">
                           <div class="input-group input-group-sm pt-2" style="width: 350px;">
                              <input type="text" name="table_search" class="form-control float-right" placeholder="Buscar">

                              <div class="input-group-append">
                                 <button type="submit" class="btn btn-default">
                                    <i class="fas fa-search"></i>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <!-- /.card-header -->

                     <div class="card-body table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                           <thead>
                           <tr>
                              <th>Nome</th>
                              <th>Email</th>
                              <th>Perfil</th>
                              <th>Ação</th>
                           </tr>
                           </thead>
                           <tbody>
                              <tr>
                                 <td>Beltrano</td>
                                 <td>beltrano.alves@gmail.com</td>
                                 <td>Administrador</td>
                                 <td>
                                    <div class="row">
                                       <button class="btn btn-sm btn-outline-primary text-bold"><i class="pt-1 fas fa-pencil-alt"></i> Editar</button>
                                       <button class="ml-3 btn btn-sm btn-outline-secondary text-bold"><i class="pt-1 fas fa-user-edit"></i> Desativar</button>
                                    </div>
                                 </td>
                              </tr>
                              <tr>
                                 <td>Fulano</td>
                                 <td>fulano.alves@gmail.com</td>
                                 <td>Colaborador</td>
                                 <td>
                                    <div class="row">
                                       <button class="btn btn-sm btn-outline-primary text-bold"><i class="pt-1 fas fa-pencil-alt"></i> Editar</button>
                                       <button class="ml-3 btn btn-sm btn-outline-secondary text-bold"><i class="pt-1 fas fa-user-edit"></i> Desativar</button>
                                    </div>
                                 </td>
                              </tr><tr>
                                 <td>Ciclano</td>
                                 <td>ciclano.alves@gmail.com</td>
                                 <td>Parceiro</td>
                                 <td>
                                    <div class="row">
                                       <button class="btn btn-sm btn-outline-primary text-bold"><i class="pt-1 fas fa-pencil-alt"></i> Editar</button>
                                       <button class="ml-3 btn btn-sm btn-outline-secondary text-bold"><i class="pt-1 fas fa-user-edit"></i> Desativar</button>
                                    </div>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <!-- /.card-body -->

                     <div class="card-footer clearfix">
                        <ul class="pagination pagination-sm m-0 float-right">
                           <li class="page-item"><a class="page-link" href="#">&laquo;</a></li>
                           <li class="page-item"><a class="page-link" href="#">1</a></li>
                           <li class="page-item"><a class="page-link" href="#">2</a></li>
                           <li class="page-item"><a class="page-link" href="#">3</a></li>
                           <li class="page-item"><a class="page-link" href="#">&raquo;</a></li>
                        </ul>
                     </div>
                     <!-- /.card-footer -->

                  </div>
                  <!-- /.card -->
               </div>
            </div>
            <!-- /.row -->
         </div>
      </section>
      <!-- /.content -->
   </div>
</template>

<script>
export default {
   name: "Users"
}
</script>

<style scoped>

</style>
